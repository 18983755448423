import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../datlaz/modules/auth";
import Hotjar from "@hotjar/browser";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {

  constructor(private readonly authService: AuthService) { }

  identifyUser() {
    if (environment.analytics.identify) {
      window.rudderanalytics.identify(this.authService.getLoggedUser().ecotx_sid);
      if (environment.hotjar.enabled && environment.hotjar.identify) {
        Hotjar.identify(this.authService.getLoggedUser().ecotx_sid, {
          username: this.authService.getLoggedUser().name,
          email: this.authService.getLoggedUser().email,
        });
      }
    }
  }

  trackLogoutMultisession() {
    if (environment.analytics.trackLogout) {
      const eventProperties = {
        u: this.authService.getLoggedUser().ecotx_sid,
        t: Date.now(),
        s: "logout",
      };
      window.rudderanalytics.track("lms", eventProperties);
    }
  }

  trackWarnMultisession() {
    if (environment.analytics.trackLogout) {
      const eventProperties = {
        u: this.authService.getLoggedUser().ecotx_sid,
        t: Date.now(),
        s: "warn",
      };
      window.rudderanalytics.track("lms", eventProperties);
    }
  }

  trackDashboardAccess(dashboardId: string) {
    if (environment.analytics.trackDashboardAccess) {
      const eventProperties = {
        u: this.authService.getLoggedUser().ecotx_sid,
        d: dashboardId,
      };
      window.rudderanalytics.track("uda", eventProperties);
    }
  }

}
