import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { AnalyticsService } from "../../analytics/analytics.service";
import { AuthService } from "../../../datlaz/modules/auth";
import {
  NotificationWSType,
  Payload,
  WSNotificationAction,
} from "../_models/payload.model";
import { DisconnectModalComponent } from "../disconnect-modal/disconnect-modal.component";
import { UserNotificationResponse } from "../dto/user-notification-response.dto";

@Injectable({
  providedIn: "root",
})
export class UserNotificationService {
  private getToasterContent(key: string): string {
    switch (key) {
      case "COMPLETED":
        return this.translateService.instant(
          "USER_NOTIFICATION.IMPORT_COMPLETED"
        );
      case "FAILED":
        return this.translateService.instant("USER_NOTIFICATION.FAILED_IMPORT");
      default:
        return key;
    }
  }

  private notificationsSubject = new Subject<Notification[]>();
  private newNotificationCountSubject = new Subject<number>();
  notifications$ = this.notificationsSubject.asObservable();
  newNotificationCount$ = this.newNotificationCountSubject.asObservable();
  private notificationsBuffer = [];

  private newNotificationSubject = new BehaviorSubject<boolean>(false);
  newNotification$ = this.newNotificationSubject.asObservable();

  constructor(
    @Inject(HttpClient) private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
    private readonly modalService: NgbModal,
    private readonly analyticsService: AnalyticsService
  ) {}

  updateNewNotification(status: boolean) {
    this.newNotificationSubject.next(status);
  }

  getUserNotifications() {
    const url = `${environment.userProfileApiUrl}/users/${this.authService.currentUserValue.sid}/client_app/ecotx-platform/notifications`;
    return this.http.get(url).pipe(
      map((response: Notification[]) => {
        this.notificationsBuffer = response;
        this.newNotificationCountSubject.next(this.notificationsBuffer.length);
        this.notificationsSubject.next(response);
      })
    );
  }

  markAllAsRead() {
    const url = `${environment.userProfileApiUrl}/users/${this.authService.currentUserValue.sid}/client_app/ecotx-platform/notifications`;
    this.notificationsBuffer.forEach((n) => (n.read = true));
    return this.http.patch(url, null).pipe(
      map((response: UserNotificationResponse) => {
        return response;
      })
    );
  }

  dismissNotification(userNotification) {
    const index = this.notificationsBuffer.indexOf(userNotification);
    const url = `${environment.userProfileApiUrl}/users/${this.authService.currentUserValue.sid}/client_app/ecotx-platform/notifications/${userNotification.id}`;
    if (index !== -1) {
      this.notificationsBuffer.splice(index, 1);
      this.notificationsSubject.next(this.notificationsBuffer);
      this.newNotificationCountSubject.next(this.notificationsBuffer.length);
      this.http.delete(url).subscribe();
    }
  }

  dismissAllNotifications() {
    const url = `${environment.userProfileApiUrl}/users/${this.authService.currentUserValue.sid}/client_app/ecotx-platform/notifications`;
    this.notificationsBuffer = [];
    this.newNotificationCountSubject.next(0);
    this.notificationsSubject.next(this.notificationsBuffer);
    this.http.delete(url).subscribe();
  }

  processReceivedWsMessage(data: any) {
    const wsPayload: Payload = JSON.parse(data) as Payload;

    if (
      wsPayload.action === WSNotificationAction.UPDATE_USER_IMPORT_PROGRESSION
    ) {
      this.updateLayerImportProgression(wsPayload);
    } else {
      this.notificationsBuffer.unshift(wsPayload);
      this.notificationsSubject.next(this.notificationsBuffer);
      this.newNotificationCountSubject.next(this.notificationsBuffer.length);
    }

    switch (wsPayload.type) {
      case NotificationWSType.TOASTER_ERROR:
        this.toastrService.error(
          this.getToasterContent(wsPayload["content"]),
          wsPayload["title"],
          {
            progressBar: true,
            timeOut: 5000,
          }
        );
        break;
      case NotificationWSType.TOASTER_SUCCESS:
        this.toastrService.success(
          this.getToasterContent(wsPayload["content"]),
          wsPayload["title"],
          {
            progressBar: true,
            timeOut: 5000,
          }
        );
        break;
      case NotificationWSType.TOASTER_WARNING:
        this.toastrService.info(
          this.getToasterContent(wsPayload["content"]),
          wsPayload["title"],
          {
            progressBar: true,
            timeOut: 5000,
          }
        );
        break;
      default:
        break;
    }

    if (wsPayload.action === "LOGOUT") {
      this.analyticsService.trackLogoutMultisession();
      const modalRef = this.modalService.open(DisconnectModalComponent);
      modalRef.result.then(
        () => {
          this.authService.logout();
        },
        () => {
          this.authService.logout();
        }
      );
    } else if (wsPayload.action === "MULTIPLE_SESSIONS_WARN") {
      this.analyticsService.trackWarnMultisession();
    }
  }

  private updateLayerImportProgression(wsPayload: Payload): void {
    const existingNotification = this.notificationsBuffer.find(
      (n) => n.actionTarget === wsPayload.actionTarget
    );
    if (existingNotification) {
      existingNotification.title = wsPayload.title;
      existingNotification.content = wsPayload.content;
    } else {
      this.notificationsBuffer.unshift(wsPayload);
      this.notificationsSubject.next(this.notificationsBuffer);
      this.newNotificationCountSubject.next(this.notificationsBuffer.length);
    }
  }

  dismissEventNotifications() {
    this.notificationsBuffer = this.notificationsBuffer.filter(
      (n) => n.action != WSNotificationAction.UPDATE_USER_IMPORT_PROGRESSION
    );
    this.notificationsSubject.next(this.notificationsBuffer);
    this.newNotificationCountSubject.next(this.notificationsBuffer.length);
  }

  dismissAlertNotifications() {
    const url = `${environment.userProfileApiUrl}/users/${this.authService.currentUserValue.sid}/client_app/ecotx-platform/notifications`;
    this.notificationsBuffer = this.notificationsBuffer.filter(
      (n) => n.action === WSNotificationAction.UPDATE_USER_IMPORT_PROGRESSION
    );
    this.notificationsSubject.next(this.notificationsBuffer);
    this.newNotificationCountSubject.next(this.notificationsBuffer.length);
    this.http.delete(url).subscribe();
  }
}
